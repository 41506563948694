<template>
  <div class="corporateInformation-view">
    <el-tabs v-model="activeName">
      <el-tab-pane label="企业信息" name="first">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="ruleForm"
        >
          <el-form-item label="企业LOGO" prop="logo">
            <el-upload  :action="uploadUrl"
                        :headers="upParams"
                        list-type="picture-card"
                        :class="{ disabled: uploadDisabled }"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess"
                        :file-list="fileList"
                        :limit="1">
                <i slot="default" class="el-icon-plus"></i>
                <!-- <div slot="tip" class="el-upload__tip">上传图片</div> -->
              </el-upload>
          </el-form-item>
          <el-form-item label="企业名称" :rules="{
                required: true, message: '企业名称不能为空', trigger: 'blur'
              }">
            <el-input
              v-model="companyMsg.companyName"
              style="width: 240px; margin-right: 8px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属行业" prop="industry">
            <el-select
              v-model="companyMsg.industryId"
              style="width: 240px"
              placeholder="请选择"
            >
              <el-option
                v-for="(item) in industryList"
                :key="item.industryId"
                :label="item.industryName"
                :value="item.industryId"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="企业邀请码" prop="secretOrder">
            <el-input
              v-model="companyMsg.password"
              style="width: 240px; margin-right: 8px"
              placeholder="其他人加入企业需此密令"
            />
          </el-form-item> -->
          <el-form-item label="手机号"  placeholder="请输入"  >
            <el-input style="width: 464px;" size="medium" v-model="companyMsg.phone" />
          </el-form-item>
          <el-form-item label="公司地址" prop="address">
            <el-input style="width: 464px;" size="medium" ref="suggestId" id="suggestId" v-model="companyMsg.address" />
            <!-- <div id="baidumap"></div> -->
          </el-form-item>
          <el-form-item label="微信号" prop="wx" placeholder="请输入">
            <el-input style="width: 464px;" size="medium" v-model="companyMsg.wx"/>
          </el-form-item>
          <el-form-item label="企业公告" prop="announcements">
            <el-input
              v-model="companyMsg.companyNotice"
              type="textarea"
              size="medium"
              style="width: 464px; font-size：16px"
              :rows="6"
              placeholder="请输入"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="save"
              type="primary"
              @click="submitForm('ruleForm')"
              :disabled="!hasPermi"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="模版分配" v-if="isMkc">
        <el-form>
        <div class="typeWrap">
            <div v-for="item in TypeOptions" :key="item.value" class="typeBlock">
              <div>
                <div class="typeTitle">{{item.label}}</div>
                <div class="typeSetBox">
                  <el-form-item label="分配数量:" label-width="100px" class="item">
                      <!-- <el-input v-model="item.count" placeholder="请输入分配数量" oninput="value=value.replace(/[^0-9.]/g,'')"/> -->
                      {{ item.count}}
                  </el-form-item>
                  <el-form-item label="已使用:" label-width="100px" class="item">
                    {{ item.usedCount}}
                  </el-form-item>
                </div>

              </div>
            </div>
          </div>
          </el-form>
      </el-tab-pane>
      <!-- <el-tab-pane label="常用语设置">
        <span class="tip">设置企业名片小程序聊天默认常用语</span>
        <el-form
          :model="wordsForm"
          ref="wordsForm"
          label-width="100px"
          class="ruleForm"
        >
          <el-form-item
              v-for="(wordsItem, index) in wordsForm.wordsList"
              :label="'常用语' + (index + 1)"
              :key="wordsItem.key"
              :prop="'wordsList.' + index + '.value'"
              style="font-size：16px"
              :rules="{
                required: true, message: '常用语不能为空', trigger: 'blur'
              }"
            >
            <el-input v-model="wordsItem.value" style="width: 464px; font-size：16px; float: left; margin-right: 10px;"></el-input>
            <el-button style="float:left;" @click.prevent="removeWordsItem(index)" v-if="wordsForm.wordsList.length !==1 ">删除</el-button>
            <el-button style="float:left;" @click.prevent="adddWordsItem()" v-if="index === wordsForm.wordsList.length-1">添加</el-button>
          </el-form-item>

          <el-form-item>
            <el-button
              class="save"
              type="primary"
              @click="submitwordsForm('wordsForm')"
              :disabled="!hasPermi"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import {fetchCompany,fetchIndustryList,updCompany, fetchDisCount, fetchAddCommon, fetchGetCommonList} from '@/api/companyManage.js'
import {get} from "@/utils/request";
let ac;
export default {
  name: "corporateInformation",
  data() {
    let token = localStorage.getItem("token")
    return {
      isMkc: true,
      hasPermi: true,
      upParams: {
        token: token
      },
      activeName: "first",
      disabled: false,
      ruleForm: {
        companyLogo: "",
        companyName: "",
        industryId: "",
        password: "",
        announcements: "",
        phone: "",
        wx: "",
        address:"",
      },
      rules: {
        companyLogo: [{ required: true, message: "请上传logo" }],
        'companyMsg.companyName': [{ required: true, message: "请输入企业名称", trigger: "blur" }],
        industryId: [
          { required: true, message: "请选择所属行业", trigger: "change" },
        ],
        password: [
          { required: true, message: "请输入企业邀请码", trigger: "blur" },
        ],
        phone:[
          { required: true, message: "请填写负责人手机号", trigger: "blur" },
        ]
      },
      wordsForm: {
        wordsList:[
          {
            value: '',
            key: Date.now()
          }
        ],
      },
      uploadUrl:this.$store.state.uploadingUrl,
      ossUrl:this.$store.state.ossUrl,
      dialogImageUrl: "",
      dialogVisible: false,
      companyMsg:'', //公司信息
      industryList:'', //行业信息
      fileList:[],
      TypeOptions: [
        {
          type: 1,
          label: '宣传物料（海报、彩页、手册、手提袋、纸巾包、钥匙扣等）',
          count: 0,
          usedCount: 0,
        },
        {
          type: 2,
          label: '产品',
          count: 0,
          usedCount: 0,
        },
        // {
        //   type: 3,
        //   label: '箱标',
        //   count: 0,
        //   usedCount: 0,
        // },
        {
          type: 4,
          label: '证书',
          count: 0,
          usedCount: 0,
        },
        // {
        //   type: 5,
        //   label: '盒标',
        //   count: 0,
        //   usedCount: 0,
        // },
      ]
    };
  },
  mounted(){
    let info = JSON.parse(localStorage.getItem('info'));
    this.isMkc = info.isMkc
    this.getDisCount()
    this.getCompany()
    this.getIndustryList()
    this.getWordsList()
    // let map = new BMap.Map("baidumap");
    // ac = new BMap.Autocomplete({
    //   //建立一个自动完成的对象
    //   input: "suggestId",
    //   location: map,
    // });
    // ac.addEventListener("onConfirm", this.setValue);
  },
  created() {
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
  },
  computed: {
    uploadDisabled(){
      return this.fileList.length !== 0;
    },
  },
  beforeDestroy() {
    // ac.removeEventListener("onConfirm", this.setValue);
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList = []
      this.ruleForm.photos = ''
    },
    handProgress(event, file, fileList) {
      this.$message({
        message: "正在上传",
      });
    },
    handleSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      console.log('file',file)
      this.companyMsg.companyLogo = this.ossUrl +  file.response.data
      console.log(this.companyMsg)
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },

    /**@method 提交表单 */
    submitForm(formName) {
      let v=this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(v.$refs.suggestId)
          updCompany(this.companyMsg)
          .then(res=>{
            if(res.code == 200){
              this.$message({
                type:'success',
                message: "保存成功",
              })
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取公司信息
    getCompany(){
      get('/company/info').then(res=>{
        this.companyMsg=res
        this.fileList = [{url:res.companyLogo,uid:1}]
        console.log('数据',res)
      })
      // let params ={
      //   myCardId: JSON.parse(localStorage.getItem('info')).cardId
      // }
      //
      // fetchCompany(params)
      // .then(res=>{
      //   this.companyMsg = res.data
      //   console.log(this.companyMsg)
      //   this.fileList = [{url:this.companyMsg.companyLogo,uid:1}],
      //   this.ruleForm.companyName = this.companyMsg.companyName
      //   this.ruleForm.industryName = this.companyMsg.industryName
      //   this.ruleForm.password = this.companyMsg.password
      //   this.ruleForm.phone = this.companyMsg.phone
      //   this.ruleForm.wx=this.companyMsg.wx
      //   this.ruleForm.address = this.companyMsg.address
      // })
    },
    // 获取分配数据
    getDisCount() {
      let params = {
        companyId: JSON.parse(localStorage.getItem('info')).companyId
      }
      fetchDisCount(params).then(res => {
        if(res.data && res.data.length) {
             res.data.forEach(item => {
               this.TypeOptions.forEach( (option,index) => {
                 if(option.type === item.type) {
                   this.TypeOptions[index].count = item.count
                   this.TypeOptions[index].usedCount = item.usedCount
                 }
               })
             });
           }
      })
    },
    //获取行业列表
    getIndustryList(){
      fetchIndustryList()
      .then(res=>{
        console.log(res)
        this.industryList = res.data
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 获取常用语 (回显)
    getWordsList() {
      let v = this
      fetchGetCommonList({pageNum: 1, pageSize: 1000})
      .then(res=>{
        if(res.data.list && res.data.list.length) {
          v.wordsForm.wordsList = res.data.list.map(item => {
            item.key = item.commonId
            item.value = item.text
            return item
          })
        }
        console.log(res)
      })
    },
    /***常用语提交表单 */
    submitwordsForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.wordsForm.wordsList.map(item => item.value)
           let  addCommonDto = {
                    list: data
                }
          fetchAddCommon(addCommonDto)
          .then(res=>{
            if(res.code == 200){
              this.$message({
                type:'success',
                message: "保存成功",
              })
            }

          })
          .catch(err=>{
            console.log(err)
          })
        }
      })
    },
    // 添加一条常用语
    adddWordsItem() {
      this.wordsForm.wordsList.push({
        value: '',
        key: Date.now()
      })
    },
    // 删除一条常用语
    removeWordsItem(index) {
        if (index !== -1) {
          this.wordsForm.wordsList.splice(index, 1)
        }
    },

    /**@method 模态框打开时的回调 */
    setValue(e) {
      console.log('111')
      console.log(e)
				const {
					item: {
						value
					},
        } = e;

        this.ruleForm.address = `${value.city}${value.district}${value.business}`;
        this.companyMsg.address =  `${value.city}${value.district}${value.business}`;
        //  ac.setInputValue('赋值')
		},
  },
};
</script>

<style lang="scss" scoped>
/deep/ .disabled .el-upload--picture-card {
    display: none;
}
.corporateInformation-view {
  padding: 16px 24px;
}
/deep/.el-tabs__content{
  background: #ffffff;
}
.ruleForm {
  margin-top: 24px;
}
.cancel {
  border: 1px solid rgba(81, 203, 205, 0.4);
  color: #51cbcd;
}
.save {
  background: #51cbcd;
  border: 1px solid #51cbcd;
}

.typeWrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 20px;
}
.typeBlock{
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  width: 46%;
  margin: 0 10px 10px 10px;
  display: inline-block;
}
.typeTitle{
  height: 30px;
  border-bottom: 1px solid #ccc;
  line-height: 30px;
  padding-left: 10px;
  background: #efefef;
  color: #333;
}
.typeSetBox{
  padding: 20px 10px 0 0;
}
.tip{
  color: #f00;
  font-size: 12px;
  padding-left: 28px;
  position: relative;
  top: 10px;
}
</style>
